<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <comercial-note-modal-alert />
      <b-row>
        <b-col cols="6">
          <h1>Contratação de operações</h1>
          <h4>{{ pageSubtitle }}</h4>
        </b-col>
        <b-col cols="5" offset="1" class="d-flex justify-content-end align-items-center">
          <div class="d-block">
            <complete-registration-alert />
            <!--<comercial-note-alert />
            <commercial-note-status-alert />-->
          </div>

          <b-button
            v-if="showResume && !success"
            variant="outline-primary"
            class="ml-1"
            @click="showResume = false"
          >Voltar</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!showResume">
      <b-row class="">
        <b-col cols="8">
          <div class="p-1">
            <div class="block-content">
              <div class="d-flex justify-content-between">
                <div class="d-flex center-content w-100 mr-1">
                  <div class="picker-block">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-0">
                        Valor selecionado
                      </h6>
                      <h6 class="mb-0">
                        Disponível para hoje
                      </h6>
                    </div>
                    <vue-slider
                      v-model="valor_pagamento_frete"
                      :min="0"
                      :tooltip-formatter="(val) => moneyFormat(val, 0)"
                      :max="this.$store.state.auth.userData.limite_por_dia - this.valor_usado_dia"
                      :interval="1000"
                      class="w-100 mt-1 mb-1 custom-slider"
                      :lazy="true"
                      :disabled="disabledSelectionSlider()"
                    />
                    <div
                      class="d-flex flex-row justify-content-between align-items-center w-100"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="frete"
                        vid="frete"
                        rules="multipleOf1000"
                      >
                        <b-form-input
                          id="frete"
                          v-model="valor_pagamento_frete_2"
                          class="w-100 max-size-limit"
                          :state="errors.length > 0 ? false : null"
                          name="frete"
                          :disabled="disabledSelectionSlider()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <h5 class="mb-0">
                        {{ formattedLimitPerDay }}
                      </h5>

                    </div>
                    <div class="mt-1 tax-box">
                      <span class="span-width">Taxa {{ this.$store.state.auth.userData.empresa.taxa_juros }}% a.m.</span>
                      <div class="v-divisor-tax"></div>
                      <span class="span-width span-left">
                        Taxa de escrituração {{ this.$store.state.auth.userData.empresa.taxa_escrituracao }}%
                      </span>
                    </div>
                  </div>
                </div>
                <div class="v-devisor"></div>
                <div class="w-100 ml-1">
                  <div>
                    <p>Prazo de vencimento(dias)</p>
                    <div class="d-flex flex-row mt-1 text-prazo justify-content-between">
                      <div @click="selectPrazo('sevenDays')" ref="sevenDays" class="margin-prazo-left px-3 py-prazo w-100">07</div>
                      <div @click="selectPrazo('fifteenDays')" ref="fifteenDays" class="margin-prazo px-3 py-prazo w-100">15</div>
                      <div @click="selectPrazo('twentyTwo')" ref="twentyTwo" class="margin-prazo px-3 py-prazo w-100">22</div>
                      <div @click="selectPrazo('thirdDay')" ref="thirdDay" class="margin-prazo-right px-3 py-prazo w-100 selected-text">30</div>
                    </div>
                    <flat-pickr
                      v-model="data_emissao"
                      class="d-flex form-control mb-1 custom-flat-pickr mt-resgate bg-white"
                      :config="config"
                      placeholder="Data de resgate"
                    />
                    <span class="d-flex justify-content-between">*Valor mínimo de contratação R$ 10.000,00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-1 justify-content-between d-flex flex-row">
            <div class="small-block-content justify-content-between mr-1">
              <div class="d-flex justify-content-between">
                <div class="picker-block">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-0">
                      Limite de contratação diário
                    </h6>
                    <h6 class="mb-0 h6-opacity">
                      {{ moneyFormat(valorLimiteDia()) }}
                    </h6>
                  </div>
                  <hr>
                  <div class="d-flex justify-content-between h6-opacity">
                    <h6 class="mb-0">
                      {{ ((valorUsado() / valorLimiteDia()) * 100).toFixed(0) || 0 }}% utilizado
                    </h6>
                    <h6 class="mb-0 h6-opacity">
                      Disponível
                    </h6>
                  </div>
                  <div class="d-flex justify-content-between mt-1">
                    <b-progress :value="valorUsado()" :max="valorLimiteDia()" class="w-50" variant="warning" />
                    <h5 style="color:black;">{{ valorDisponivelDia() }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="small-block-content justify-content-between">
              <div>
                <div class="d-flex justify-content-between">
                  <div class="picker-block">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-0">
                        Limite total concedido
                      </h6>
                      <h6 class="mb-0 h6-opacity">
                        {{ moneyFormat(this.limite) }}
                      </h6>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between h6-opacity">
                      <h6 class="mb-0">
                        {{ this.totalLimitFormatted() }}% utilizado
                      </h6>
                      <h6 class="mb-0 h6-opacity">
                        Disponível
                      </h6>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                      <b-progress :value="(this.$store.state.auth.userData.limite - this.$store.state.auth.userData.limite_disponivel)" :max="this.$store.state.auth.userData.limite" class="w-50" variant="warning" />
                      <h5 style="color:black;">{{ moneyFormat(this.limite_disponivel) }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="4">
          <b-card class="cc-bg-light d-flex justify-content-center">
            <h3 class="mb-1">
              Resumo da operação
            </h3>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="font-weight-normal">
                Taxa efetiva no período
              </h5>
              <span>{{taxa_efetiva_periodo}}</span>
            </div>
            <hr>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="font-weight-normal">
                Prazo de vencimento(dias)
              </h5>
              <span>{{ prazo_em_dias }}</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="font-weight-normal">
                Data do vencimento
              </h5>
              <span>{{dataVencimento()}}</span>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="font-weight-normal">
                Data de resgate
              </h5>
              <span v-if="data_emissao !== '' || data_emissao !== undefined">{{ data_emissao || '-' }}</span>
            </div>
            <hr>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="font-weight-normal">
                Total solicitado
              </h5>
              <h5 class="font-weight-normal">
                {{ moneyFormat(valor_pagamento_frete) }}
              </h5>
            </div>
            <div class="d-flex flex-row justify-content-between mb-1">
              <h5>A pagar no vencimento</h5>
              <h5>{{ valor_pagar }}</h5>
            </div>
            <b-button
              variant="primary"
              class="w-100"
              :disabled="!canContract"
              @click="showCommercialNotesResume"
            >Contratar</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-else>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between align-items-center mb-2">
          <h3 v-if="!success" class="mb-0">
            Resumo da operação
          </h3>
          <div v-else class="d-flex align-items-center mr-1">
            <b-img
              class="mr-2"
              src="@/assets/images/dashboard/commercial-note-success.svg"
            />
            <div>
              <h3 class="mb-0">
                Operação em processamento
              </h3>
              <span>Sua nota comercial está sendo emitida e será enviada para assinatura. Em
                breve você receberá um e-mail com instruções e após a conclusão das
                assinaturas o seu dinheiro estará na conta em um prazo máximo de duas
                horas.
              </span>
            </div>
          </div>
          <b-button v-if="!success"
                    variant="primary"
                    @click="sendCommercialNote"
          >Confirmar contratação</b-button>
          <div v-else class="d-flex flex-row text-nowrap">
            <b-button
              class="mr-1"
              variant="outline-primary"
              @click="$router.push({ name: 'concessoes.show', params: { id } })"
            >Acompanhar status
            </b-button>
            <b-button variant="primary" @click="resetForm">
              Voltar ao inicio
            </b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <b-alert show variant="warning" class="p-1 border-warning">
            <feather-icon icon="AlertTriangleIcon" size="24" class="mr-1" />
            ATENÇÃO: Após a emissão, a Nota Comercial deverá ser assinada até as 14h do mesmo dia.
            Após esse horário, a operação será automaticamente cancelada e uma nova solicitação
            poderá ser feita no dia seguinte, sob o risco de alterações nas condições comercias.
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-card class="cc-bg-light d-flex justify-content-center mb-0 h-100">
            <div v-if="success" class="d-flex flex-row justify-content-between mb-1">
              <h5 class="mb-1">
                Status
              </h5>
              <span class="d-flex justify-content-end">
                <div v-html="comercialNoteStatusBadge(status)" />
              </span>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1 text-dark">
                Total a pagar no vencimento
              </h6>
              <h6 class="mb-1 text-dark">
                {{ valor_pagar }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Total solicitado
              </h6>
              <h6 class="mb-1">
                {{ moneyFormat(valor_pagamento_frete) }}
              </h6>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="cc-bg-light d-flex justify-content-center mb-0 h-100">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Taxa efetiva no período
              </h6>
              <h6 class="mb-1">
                {{ taxa_efetiva_periodo }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Prazo de vencimento (dias)
              </h6>
              <h6 class="mb-1">
                {{ prazo }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Data de vencimento
              </h6>
              <h6 class="mb-1">
                {{ dataVencimento() }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Data de resgate
              </h6>
              <h6 class="mb-1">
                {{ data_emissao }}
              </h6>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="pt-2 pl-2">
        <p>
          Os recursos da Nota Comercial serão depositados em sua conta junto a Roadcard
          para pagamento de despesas de frete.
        </p>
      </b-row>
    </b-card>

    <b-modal
      id="complete-registration-modal"
      centered
      hide-footer
      header-bg-variant="white"
    >
      <div class="d-flex flex-column justify-content-start align-items-start">
        <h3 class="text-primary">
          Complete seu cadastro
        </h3>
        <br>
        <p>
          Para prosseguir com a contratação do seu crédito precisamos que você complete
          algumas informações de cadastro importantes e envie os seguintes documentos:
        </p>
        <ul>
          <li>Estatuto social</li>
          <li>Documento com foto</li>
          <li>Comprovante de residência</li>
        </ul>
        <span>Continuar para completar o seu cadastro agora?</span>
        <div class="mt-2 mb-1 d-flex justify-content-center w-100">
          <b-button
            variant="outline-primary"
            class="mr-2"
            @click="$bvModal.hide('complete-registration-modal')"
          >Cancelar</b-button>
          <b-button variant="primary" @click="continuarClick()">
            Continuar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="complete-documentation-modal"
      centered
      hide-footer
      header-bg-variant="white"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="text-center mb-2">Não é possível prosseguir com a contratação. Antes de continuar, destacamos que
          é indispensável regularizar o seu cadastro.</span>
        <b-button
          variant="primary"
          class="mb-1"
          @click="$router.push({ name: 'minha.conta' })"
        >Regularizar agora</b-button>
      </div>
    </b-modal>

    <b-modal id="in-progress-modal" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3>Cadastro em análise</h3>
        <span class="text-center mb-2">Suas informações precisam passar por um processo de análise e validação. Esse
          processo só ocorre na sua primeira contratação, ou em caso de eventuais
          alterações da documentação.</span>
        <span class="text-center mb-2">Fique tranquilo, em até 48 horas você será informado para continuar com o seu
          processo de contratação de crédito.</span>
        <b-button
          variant="primary"
          class="mb-1"
          @click="$bvModal.hide('in-progress-modal')"
        >Aguardar</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BButton,
  BLink,
  BIcon,
  BFormInput,
  BModal,
  BBadge,
  BAlert,
  VBTooltip,
  BProgress,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import {
  moneyFormat,
  comercialNoteStatusBadge,
} from '@core/comp-functions/data_visualization/datatable'
import { brlToFloat } from '@core/comp-functions/money/money'
import CompleteRegistrationAlert from '@/views/client/dashboard/components/CompleteRegistrationAlert.vue'
// import ComercialNoteAlert from "@/views/client/dashboard/components/ComercialNoteAlert.vue";
import ComercialNoteModalAlert from '@/views/client/dashboard/components/ComercialNoteModalAlert.vue'
// import CommercialNoteStatusAlert from "@/views/client/dashboard/components/CommercialNoteStatusAlert.vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { multiple1000 } from '@validations'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { c } from '@core/utils/filter'

export default {
  name: 'Credit',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BIcon,
    BBadge,
    BModal,
    BButton,
    BAlert,
    VueSlider,
    flatPickr,
    BFormInput,
    BProgress,
    ValidationProvider,
    ValidationObserver,
    // ComercialNoteAlert,
    CompleteRegistrationAlert,
    ComercialNoteModalAlert,
    // CommercialNoteStatusAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id: null,
      simulating: false,
      showResume: false,
      success: false,
      tax: 0.8,
      limite: parseFloat(this.$store.state.auth.userData.limite),
      valor_pagamento_frete: 0,
      valor_pagamento_frete_2: 'R$ 0',
      valor_vale_pedagio: 0,
      valor_vale_pedagio_2: 'R$ 0',
      valor_vale_combustivel: 0,
      valor_vale_combustivel_2: 'R$ 0',
      data_emissao: '',
      status: '',
      valor_pagar: 0,
      limite_disponivel: parseFloat(this.$store.state.auth.userData.limite_disponivel),
      data_vencimento: null,
      prazo: 30,
      taxa_efetiva_periodo: '',
      taxa_juros: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 0,
      },
      config: {
        dateFormat: 'd/m/Y',
        minDate: 'today',
        disable: [this.disableDates],
        locale: Portuguese,
      },
      limite_por_dia: parseFloat(this.$store.state.auth.userData.limite_por_dia),
      valor_limite_disponivel_dia: parseFloat(this.$store.state.auth.userData.limite_por_dia - this.$store.state.auth.userData.limite_diario_usado),
      valor_usado_dia: parseFloat(this.$store.state.auth.userData.limite_diario_usado),
      prazo_em_dias: 30,
    }
  },
  computed: {
    formattedRetentionRate() {
      return (
        (brlToFloat(this.valor_pagamento_frete)
          * this.$store.state.auth.userData.empresa.taxa_escrituracao)
        / 100
      )
    },
    sendCommercialNotePayload() {
      return {
        data_emissao: this.data_emissao,
        valor_emissao: this.valor_pagamento_frete,
        prazo: this.prazo,
      }
    },
    formattedLimit() {
      return moneyFormat(parseFloat(this.$store.state.auth.userData.empresa.limite))
    },
    formattedLimitPerDay() {
      return moneyFormat(parseFloat(this.$store.state.auth.userData.limite_por_dia - this.valor_usado_dia) )
    },
    getMaxValue() {
      const maxFrete = Math.abs(
        this.limite - this.valor_vale_pedagio - this.valor_vale_combustivel,
      )
      const maxPedagio = Math.abs(
        this.limite - this.valor_pagamento_frete - this.valor_vale_combustivel,
      )
      const maxAbastecimento = Math.abs(
        this.limite - this.valor_pagamento_frete - this.valor_vale_pedagio,
      )

      return {
        frete: parseInt(parseFloat(maxFrete).toFixed(0)),
        pedagio: parseInt(parseFloat(maxPedagio).toFixed(0)),
        abastecimento: parseInt(parseFloat(maxAbastecimento).toFixed(0)),
      }
    },
    totalAmount() {
      return moneyFormat(this.valor_pagamento_frete)
    },
    totalAmountWithTax() {
      return moneyFormat(
        this.valor_pagamento_frete
          - this.valor_pagamento_frete
            * (this.$store.state.auth.userData.empresa.taxa_escrituracao / 100),
      )
    },
    canContract() {
      return Boolean(this.valor_pagamento_frete >= 10000) && Boolean(this.data_emissao) && brlToFloat(this.valor_pagamento_frete) % 1000 === 0
    },
    registrationSituation() {
      const {
        status_analise,
        status_documentacao,
        status_limite,
      } = this.$store.state.auth.userData.empresa

      const situation = status_documentacao === 'reprovado' ? 'reproved' : ''

      const message = {
        incomplete: {
          title: '',
          subtitle: '',
        },
        reproved: {
          title: '',
          subtitle: '',
        },
      }
      return this.$store.state.auth.userData.empresa.situacao_cadastral
    },
    statusDocumentation() {
      return this.$store.state.auth.userData.empresa.status_documentacao
    },
    formattedDueDate() {
      if (!this.data_vencimento) return ' - '

      const date = parse(this.data_vencimento, 'yyyy-MM-dd', new Date())

      return format(date, 'dd/MM/yyyy')
    },
    pageSubtitle() {
      return this.showResume
        ? 'Revise os detalhes da sua contratação'
        : 'Defina abaixo o valor que você deseja contratar'
    },
    taxaEscrituracaoIsNullOrZero() {
      return (
        this.$store.state.auth.userData.empresa.taxa_escrituracao === '0.0'
        || this.$store.state.auth.userData.empresa.taxa_escrituracao === null
      )
    },
  },
  watch: {
    valor_pagamento_frete(newValue) {
      this.valor_pagamento_frete_2 = moneyFormat(newValue, 0)
      this.simulate()
    },
    valor_vale_combustivel(newValue) {
      this.valor_vale_combustivel_2 = moneyFormat(newValue, 0)
      this.simulate()
    },
    valor_vale_pedagio(newValue) {
      this.valor_vale_pedagio_2 = moneyFormat(newValue, 0)
      this.simulate()
    },
    valor_pagamento_frete_2(newValue) {
      if (typeof newValue === 'string') this.valor_pagamento_frete = brlToFloat(newValue)
    },
    valor_vale_combustivel_2(newValue) {
      if (typeof newValue === 'string') { this.valor_vale_combustivel = brlToFloat(newValue) }
    },
    valor_vale_pedagio_2(newValue) {
      if (typeof newValue === 'string') this.valor_vale_pedagio = brlToFloat(newValue)
    },
    data_emissao() {
      this.valor_pagamento_frete = 0
      this.valor_pagamento_frete_2 = 'R$ 0'
      this.simulate()
    },
    data_vencimento() {
      this.getEmpresaLimits()
    }
  },
  async mounted() {
    await this.$store.dispatch('auth/getUserData');
  },
  created() {
    const selected_valor_pagamento_frete = localStorage.getItem(
      'selected_valor_pagamento_frete',
    )
    if (selected_valor_pagamento_frete != null) {
      this.valor_pagamento_frete = selected_valor_pagamento_frete
    }
  },
  methods: {
    continuarClick() {
      localStorage.setItem('selected_valor_pagamento_frete', this.valor_pagamento_frete)
      this.$router.push({ name: 'complete-registration' })
    },
    disablingWeekends(date) {
      return [0, 6].includes(date.getDay())
    },
    disabelCurrentDateIfHourIsGreatherLimit(date) {
      const current = new Date()
      const limitHour = this.getLimitTime()
      const limit = new Date()
      limit.setHours(limitHour[0], limitHour[1], limitHour[2])
      const isToday = date.toDateString() === current.toDateString()
      return isToday && (current.getTime() >= limit.getTime())
    },
    getLimitTime(){
      let limitTime = []
      try {
        const fundo = this.$store.state.auth.userData.empresa.fundo_para_emissao_nota_comercial
        limitTime = fundo.hora_limite_emissao.split('T')[1].split('.')[0].split(':')
        limitTime = [parseInt(limitTime[0]), parseInt(limitTime[1]), parseInt(limitTime[2])]
      } catch (error) {
        limitTime = [13, 0, 0]
      }
      return limitTime
    },
    disableDates(date) {
      return this.disablingWeekends(date) || this.disabelCurrentDateIfHourIsGreatherLimit(date)
    },
    resetForm() {
      this.valor_pagamento_frete = 0
      this.valor_vale_pedagio = 0
      this.valor_vale_combustivel = 0
      this.data_emissao = null
      this.success = false
      this.showResume = false
    },
    moneyFormat(value, minimumFractionDigits = 2) {
      return moneyFormat(parseInt(value), minimumFractionDigits)
    },
    showCommercialNotesResume() {
      if (this.statusDocumentation === 'nao_enviado') return this.showCompleteRegistrationModal()
      if (this.statusDocumentation === 'reprovado') return this.showCompleteDocumentationModal()
      if (this.statusDocumentation === 'em_validacao') return this.showInProgressModal()

      return (this.showResume = true)
    },
    sendCommercialNote() {
      try {
        this.$swal
          .fire({
            title: 'Deseja continuar?',
            text: 'Enviar solicitação de nota comercial de crédito.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Enviar!',
          })
          .then(async result => {
            if (result.value) {
              try {
                this.$swal.showLoading()
                localStorage.removeItem('selected_valor_pagamento_frete')
                const { data } = await this.$store.dispatch(
                  'dashboard/sendCommercialNote',
                  this.sendCommercialNotePayload,
                )
                this.data_vencimento = data.data_vencimento
                this.id = data.id
                this.limite_disponivel = this.moneyFormat(data.limite_disponivel)
                this.status = data.status
                this.success = true
                this.$swal.fire({
                  title: 'Sucesso!',
                  text: 'Solicitação de nota comercial de crédito enviada com sucesso.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                })
              } catch (error) {
                // this.$swal.fire({
                //   title: 'Erro!',
                //   text: error.response.data,
                //   icon: 'error',
                //   confirmButtonText: 'Ok',
                // })
              }
            }
          })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocorreu um erro ao enviar a solicitação.',
        })
      }
    },
    showCompleteRegistrationModal() {
      this.$bvModal.show('complete-registration-modal')
    },
    showCompleteDocumentationModal() {
      this.$bvModal.show('complete-documentation-modal')
    },
    showInProgressModal() {
      this.$bvModal.show('in-progress-modal')
    },
    async simulate() {
      if (this.simulating) return

      this.simulating = true
      try {
        let data_resgate = typeof this.data_emissao === 'string'
          ? parse(this.data_emissao, 'dd/MM/yyyy', new Date())
          : this.data_emissao
        data_resgate = format(data_resgate, 'yyyy/MM/dd')

        if (isNaN(this.valor_pagamento_frete)) this.valor_pagamento_frete = 0

        const { data } = await this.$store.dispatch('dashboard/simulate', {
          data_resgate,
          valor_emprestimo: this.valor_pagamento_frete,
          prazo: this.prazo
        })
        this.data_vencimento = data.data_vencimento
        this.taxa_efetiva_periodo = data.taxa_efetiva_periodo.replace('.', ',') + '%'
        this.prazo_em_dias = data.prazo
        this.valor_pagar = parseFloat(data.valor_pagar).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      } catch (error) {
        this.valor_pagar = moneyFormat(0)
        this.data_emissao = null
        this.data_vencimento = null
        this.valor_pagamento_frete = 0
      } finally {
        this.simulating = false
      }
    },
    comercialNoteStatusBadge,
    selectPrazo(prazo) {
      switch (prazo) {
        case 'sevenDays':
          this.$refs.sevenDays.classList.add("selected-text");
          this.$refs.fifteenDays.classList.remove("selected-text");
          this.$refs.twentyTwo.classList.remove("selected-text");
          this.$refs.thirdDay.classList.remove("selected-text");
          this.prazo = 7
          this.valor_pagamento_frete = 0
          this.valor_pagamento_frete_2 = 'R$ 0'
          break;
        case 'fifteenDays':
          this.$refs.sevenDays.classList.remove("selected-text");
          this.$refs.fifteenDays.classList.add("selected-text");
          this.$refs.twentyTwo.classList.remove("selected-text");
          this.$refs.thirdDay.classList.remove("selected-text");
          this.prazo = 15
          this.valor_pagamento_frete = 0
          this.valor_pagamento_frete_2 = 'R$ 0'
          break;
        case 'twentyTwo':
          this.$refs.sevenDays.classList.remove("selected-text");
          this.$refs.fifteenDays.classList.remove("selected-text");
          this.$refs.twentyTwo.classList.add("selected-text");
          this.$refs.thirdDay.classList.remove("selected-text");
          this.prazo = 22
          this.valor_pagamento_frete = 0
          this.valor_pagamento_frete_2 = 'R$ 0'
          break;
        case 'thirdDay':
          this.$refs.sevenDays.classList.remove("selected-text");
          this.$refs.fifteenDays.classList.remove("selected-text");
          this.$refs.twentyTwo.classList.remove("selected-text");
          this.$refs.thirdDay.classList.add("selected-text");
          this.prazo = 30
          this.valor_pagamento_frete = 0
          this.valor_pagamento_frete_2 = 'R$ 0'
          break;
        default:
          console.log('erro no prazo');
      }

      this.simulate()
    },
    formatDate(value) {
      if (!value) return "";

      let date = parse(value, "yyyy-MM-dd", new Date());

      return format(date, "dd/MM/yyyy");
    },
    dataVencimento() {
      return (this.data_vencimento !== null & this.data_vencimento !== '') ?
      this.formatDate(this.data_vencimento) : '-'
    },
    disabledSelectionSlider() {
      return parseFloat(this.$store.state.auth.userData.limite_por_dia - this.valor_usado_dia) === 0.0 || this.dataVencimento() == '-'
    },
    async getEmpresaLimits(currentDate) {
      try {
        const { data } = await this.$store.dispatch('dashboard/getEmpresaLimits', { data_vencimento: this.data_vencimento || currentDate })

        this.limite_por_dia = data.limite_por_dia
        this.valor_limite_disponivel_dia = data.valor_disponivel
        this.valor_usado_dia = data.valor_usado

        if (this.valor_limite_disponivel_dia === "0.0") {
          this.valor_pagamento_frete = 0
          this.valor_pagamento_frete_2 = moneyFormat(0)
        }
      } catch (error) {
        console.log(error);
      }
    },
    valorUsado() {
      return this.valor_usado_dia
    },
    valorLimiteDia() {
      return this.$store.state.auth.userData.limite_por_dia
    },
    valorDisponivelDia() {
      return moneyFormat(this.valor_limite_disponivel_dia)
    },
    totalLimitFormatted() {
      this.limite_disponivel = this.$store.state.auth.userData.limite_disponivel
      const usedLimit = (this.$store.state.auth.userData.limite - this.$store.state.auth.userData.limite_disponivel)

      return ((usedLimit / this.$store.state.auth.userData.limite) * 100).toFixed(0)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@media (max-width: 1240px) {
  .w-50 {
    width: 28% !important;
  }
}

.gap-1 {
  gap: 8px;
}

.small-block-content {
  border: solid rgba(218, 225, 232, 1) 1px;
  padding: 25px;
  border-radius: 5px;
  width: 100%; /* Define a largura como 100% */
  justify-content: space-around;
}

.block-content {
  border: solid rgba(218, 225, 232, 1) 1px;
  padding: 25px;
  border-radius: 5px;
  min-width: 605px;
}

.margin-prazo {
  border-left: solid rgba(9, 50, 114, 1) 1px;
  border-top: solid rgba(9, 50, 114, 1) 1px;
  border-bottom: solid rgba(9, 50, 114, 1) 1px;
  text-align: center;
}

.margin-prazo-left {
  border-left: solid rgba(9, 50, 114, 1) 1px;
  border-top: solid rgba(9, 50, 114, 1) 1px;
  border-bottom: solid rgba(9, 50, 114, 1) 1px;
  border-radius: 6px 0px 0px 6px;
  text-align: center;
}

.margin-prazo-right {
  border: solid rgba(9, 50, 114, 1) 1px;
  border-radius: 0px 6px 6px 0px;
  text-align: center;
}

.h-devisor {
  border-top: solid rgba(218, 225, 232, 1) 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.v-devisor {
  border-left: solid rgba(218, 225, 232, 1) 1px;
  height: 167px;
  margin-right: 10px;
  margin-left: 10px;
}

.picker-block {
  margin-right: 20px;
  min-width: 290px;
  max-width: 570px;
  width: 100%;
}

.max-size-limit {
  max-width: 160px;
}

.tax-box {
  background: rgba(248, 195, 61, 0.15);
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: solid rgba(248, 195, 61, 1) 1px;
  border-radius: 6px;
  text-align: -webkit-center;
  font-size: 12px;
  font-weight: 600;
}

.span-width {
  place-self: center;
  min-width: 157px;
  max-width: 285px;
  padding: 4px;
}

.py-prazo {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mt-resgate {
  margin-top: 33px;
  display: flex;
}

.center-content {
  justify-content: space-between;
}

.v-divisor-tax {
  border-left: solid rgba(248, 195, 61, 1) 1px;;
}

.text-prazo {
  color: rgba(9, 50, 114, 1);
  font-weight: 500;
  cursor: pointer;
}

.selected-text {
  color: white !important;
  background-color: rgba(9, 50, 114, 1);
}

.h6-opacity {
  opacity: 0.8;
}

.custom-slider {
  cursor: pointer;
}
</style>

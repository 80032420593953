<template>
  <b-modal 
    id="modal-nota-alerta" 
    centered 
    no-stacking
    size="md"
    @hidden="resetModal"
    hide-footer>
    <div class="d-block nota-expired-content"
    >
      <b-img :src="noteStatus.img" />
      <div class="">
        <h4 class="mb-1 mt-2">{{ noteStatus.title }}</h4>
        <span class="d-block">{{ noteStatus.titleSecond }}</span>
        <span class="mb-3 d-block">{{ noteStatus.subtitle }}</span>
      </div>
      <b-button variant="primary" @click="sendToComercialNoteRoute" class="mb-2">Saiba mais</b-button>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BImg, BButton } from 'bootstrap-vue'

export default {
  name: 'ComercialNoteModalAlert',
  components: {
    BModal,
    BImg,
    BButton,
  },
  data() {
    return {
      expiredComercialNotes: [],
      toExpiringComercialNotes: [],
      modalOpen: false,
    }
  },
  async mounted() {
    this.getStatusExpiredNotes()
    this.getStatusExecutedNotes()
  },
  computed: {
    noteStatus () {
      const status = {
        'vencida': {
          title: 'Nota Comercial Vencida',
          titleSecond: 'Você tem uma Nota Comercial vencida.',
          subtitle: 'Faça hoje o seu pagamento e evite mais multa e encargos.',
          img: require('@/assets/images/dashboard/alert-octagon.svg')
        },
        'a_vencer': {
          title: 'Nota Comercial aguardando pagamento',
          titleSecond: 'Você tem uma Nota Comercial aguardando o seu pagamento.',
          subtitle: 'Faça hoje o seu pagamento e evite multa e encargos',
          img: require('@/assets/images/dashboard/clock.svg')
        },
      }
      if (this.expiredComercialNotes.length){
        return status['vencida']
      } else if (this.toExpiringComercialNotes.length){
        return status['a_vencer']
      } else {
        return {}
      }
    }
  },
  methods: {
    sendToComercialNoteRoute() {
      return this.$router.push({ name: 'concessoes.index' })
    },
    resetModal() {
      this.modalOpen = false
    },
    async getStatusExecutedNotes(){
      const { data } = await this.$store.dispatch('concession/checkForToExpiringNotes')
      let showModal = false
      this.toExpiringComercialNotes = data

      this.toExpiringComercialNotes.forEach(function (comercialNote) {
        let today_date = new Date()
        let comercialNoteExpirationDate = new Date(comercialNote.data_vencimento + 'T00:00:00-0300')
        let diffDates = Math.ceil(Math.abs(comercialNoteExpirationDate - today_date))
        let diffInDays = diffDates / (1000 * 60 * 60 * 24)

        if(diffInDays <= 5) {
          showModal = true
        }
      });

      if (showModal){
        await this.$bvModal.show("modal-nota-alerta")
      }
    },
    async getStatusExpiredNotes(){
      const { data } = await this.$store.dispatch('concession/checkForExpiredNotes')
      this.expiredComercialNotes = data
      if (this.expiredComercialNotes.length){
        await this.$bvModal.show("modal-nota-alerta")
      }
    }
  },
}
</script>

<style lang="scss">
  .nota-expired-content{
    text-align: center;
  }
  #modal-nota-alerta  .modal-header{
    background-color: rgba(0, 0, 0, 0) !important;
  }
</style>